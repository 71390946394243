body {
	--orange: rgb(255, 122, 22);
	--lightblue: rgb(89, 163, 217);
	--darkblue: rgb(19, 100, 185);
	--gray: rgb(247, 245, 242);
	--brown: rgb(179, 151, 123);
	--red: rgb(148, 45, 37);
	--difficulty: rgb(82, 40, 126);
	--challenge: rgb(117, 19, 23);
	--boost: rgb(118, 205, 219);
	--ability: rgb(70, 172, 78);
	--proficiency: rgb(254, 240, 53);
	--orangeFade: rgba(255, 122, 22, 0.2);
	--lightblueFade: rgba(89, 163, 217, 0.2);
}

hr {
	margin-top: 0.25rem;
	margin-bottom: 0.25rem;
	border: 0;
	border-top: 2px solid rgba(0, 0, 0, 0.1);
}

table {
	vertical-align: middle;
}

thead {
	font-weight: 600;
}

label {
	padding-bottom: 0 !important;
	padding-top: 0 !important;
	padding-right: 0 !important;
}

.table th {
	padding: 0.4rem;
}

.table td {
	padding: 0.4rem;
}

.table-dice {
}

.table-career {
	text-align: center;
}

.table-rank {
	text-align: center;
}

.table-name {
	text-align: left;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type="number"] {
	-moz-appearance: textfield;
}

.text-pre {
	white-space: pre-line;
}

.w-10 {
	width: 10%;
}

.w-20 {
	width: 20%;
}

.w-30 {
	width: 30%;
}

.w-40 {
	width: 40%;
}

.w-60 {
	width: 60%;
}

.w-70 {
	width: 70%;
}

.w-80 {
	width: 80%;
}

.w-90 {
	width: 90%;
}
.VehicleStatBlock {
	position: relative;
	text-align: center;
	color: black;
	padding: 0;
	margin: 0.15rem;
	width: 40rem;
}

.vehicleStat {
	position: absolute;
	display: inline-flex;
	align-items: center;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
	flex: unset;
	padding: 0 0;
	font-weight: bold;
	font-size: 1.5rem;
}

.vehicleStat-silhouette {
	top: 27%;
	left: 8.5%;
	font-size: 2.5rem;
}

.vehicleStat-maxSpeed {
	top: 27%;
	left: 24.5%;
	font-size: 2.5rem;
}

.vehicleStat-handling {
	top: 27%;
	left: 40.9%;
	font-size: 2.5rem;
}

.vehicleStat-defense {
	top: 27%;
	left: 61.5%;
}

.vehicleStat-armor {
	top: 27%;
	left: 85%;
}

.vehicleStat-hullTraumaThreshold {
	top: 78.5%;
	left: 57%;
}

.vehicleStat-systemStrainThreshold {
	top: 78.5%;
	left: 81%;
}

.vehicleStat-currentHullTrauma {
	top: 77.5%;
	left: 65%;
	width: 2.2rem;
	font-size: 1.2rem;
}

.vehicleStat-currentSystemStrain {
	top: 77.5%;
	left: 89%;
	width: 2.2rem;
	font-size: 1.2rem;
}

@media only screen
and (max-width: 480px) {

	.VehicleStatBlock {
		max-width: 100vw
	}

	.vehicleStat-silhouette,
	.vehicleStat-maxSpeed,
	.vehicleStat-handling {
		font-size: 1.5rem;
	}

	.vehicleStat-defense,
	.vehicleStat-armor,
	.vehicleStat-hullTraumaThreshold,
	.vehicleStat-systemStrainThreshold {
		font-size: 1.0rem;
	}

	.vehicleStat-currentHullTrauma,
	.vehicleStat-currentSystemStrain {
		height: 1rem;
		width: 1.8rem;
		font-size: 0.8rem;
	}
}
.react-tabs__tab-list {
	width: 100%;
	text-align: left;
	border-bottom: 1px solid #aaa;
	margin: 0 0 10px;
	padding: 0;
}

.react-tabs__tab {
	display: inline-block;
	border: 1px solid transparent;
	border-bottom: none;
	bottom: -1px;
	position: relative;
	list-style: none;
	padding: 6px 12px;
	cursor: pointer;
	font-size: 0.8rem;
}

.react-tabs__tab--selected {
	background: var(--gray);
	border-color: #aaa;
	color: black;
	border-radius: 5px 5px 0 0;
}

.react-tabs__tab--disabled {
	color: GrayText;
	cursor: default;
}

.react-tabs__tab:focus {
	box-shadow: 0 0 5px hsl(208, 99%, 50%);
	border-color: hsl(208, 99%, 50%);
	outline: none;
}

.react-tabs__tab:focus:after {
	content: "";
	position: absolute;
	height: 5px;
	left: -4px;
	right: -4px;
	bottom: -5px;
	background: #fff;
}

.react-tabs__tab-panel {
	display: none;
}

.react-tabs__tab-panel--selected {
	display: block;
}

@font-face {
	font-family: 'Bebas Neue';
	src: url(/static/media/BebasNeueBold.06a22213.woff2) format('woff2'),
	url(/static/media/BebasNeueBold.a31dd45e.woff) format('woff');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Bebas Neue';
	src: url(/static/media/BebasNeueRegular.bf31fc3b.woff2) format('woff2'),
	url(/static/media/BebasNeueRegular.06aaf065.woff) format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Avenir Next LT Pro';
	src: url(/static/media/AvenirNextLTPro-Regular.da973973.woff2) format('woff2'),
	url(/static/media/AvenirNextLTPro-Regular.ee81bb29.woff) format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Avenir Next LT Pro';
	src: url(/static/media/AvenirNextLTPro-Bold.4078664b.woff2) format('woff2'),
	url(/static/media/AvenirNextLTPro-Bold.4130f408.woff) format('woff');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Minion Pro';
	src: url(/static/media/MinionPro-Regular.0be3417a.woff2) format('woff2'),
	url(/static/media/MinionPro-Regular.60b2d2a2.woff) format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Minion Pro';
	src: url(/static/media/MinionPro-Bold.0d20ef11.woff2) format('woff2'),
	url(/static/media/MinionPro-Bold.7d6242a7.woff) format('woff');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'American Captian';
	src: url(/static/media/american-captain-webfont.ae99d117.woff2) format('woff2'),
	url(/static/media/american-captain-webfont.a7e04950.woff) format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Archistico';
	src: url(/static/media/archistico_simple.3d38367f.woff2) format('woff2'),
	url(/static/media/archistico_simple.ba518fab.woff) format('woff');
	font-weight: normal;
	font-style: normal;

}

@font-face {
	font-family: 'Archistico';
	src: url(/static/media/archistico_bold.07b14e0c.woff2) format('woff2'),
	url(/static/media/archistico_bold.978720fb.woff) format('woff');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Charis SIL';
	src: url(/static/media/CharisSIL.2989f221.woff2) format('woff2'),
	url(/static/media/CharisSIL.695cffd6.woff) format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Charis SIL';
	src: url(/static/media/CharisSIL-Bold.53369d92.woff2) format('woff2'),
	url(/static/media/CharisSIL-Bold.421970cb.woff) format('woff');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Bolton';
	src: url(/static/media/Bolton.1af62f81.woff2) format('woff2'),
	url(/static/media/Bolton.1e442e0b.woff) format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Bolton';
	src: url(/static/media/BoltonBold.5d624c68.woff2) format('woff2'),
	url(/static/media/BoltonBold.87451fdc.woff) format('woff');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Bolton';
	src: url(/static/media/BoltonItalic.5680b422.woff2) format('woff2'),
	url(/static/media/BoltonItalic.c907e642.woff) format('woff');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'Bolton';
	src: url(/static/media/BoltonBoldItalic.0d76f2c9.woff2) format('woff2'),
	url(/static/media/BoltonBoldItalic.163eeeb4.woff) format('woff');
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: 'Adobe Garamond Pro';
	src: url(/static/media/AGaramondPro-Bold.9525863c.woff2) format('woff2'),
	url(/static/media/AGaramondPro-Bold.eeff799a.woff) format('woff');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Adobe Garamond Pro';
	src: url(/static/media/AGaramondPro-Regular.42df93d8.woff2) format('woff2'),
	url(/static/media/AGaramondPro-Regular.8f20f5e9.woff) format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Adobe Garamond Pro';
	src: url(/static/media/AGaramondPro-Italic.85e4b829.woff2) format('woff2'),
	url(/static/media/AGaramondPro-Italic.39434389.woff) format('woff');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'Adobe Garamond Pro';
	src: url(/static/media/AGaramondPro-BoldItalic.62c81750.woff2) format('woff2'),
	url(/static/media/AGaramondPro-BoldItalic.c20bc871.woff) format('woff');
	font-weight: bold;
	font-style: italic;
}


.form-check-label {
	margin-bottom: 0;
	vertical-align: middle;
}

.header {
	font-size: 1.25rem;
	margin-bottom: 0;
	margin-top: 0.5rem;
	line-height: 1.2;
	color: inherit;
	font-weight: bold;
}

.noUnderLine, .noUnderLine:hover, .noUnderLine:focus {
	text-decoration: none;
}

.textSymbols {
	vertical-align: middle;
	height: 1.2rem;
}

.characterImage {
	max-height: 18rem;
	object-fit: contain;
}

.svg {
	width: 100%;
}

.absolute {
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
}

.talentDesc {
	overflow-y: auto;
	white-space: pre-line;
}

.row-hide {
	display: none;
}

.talentCard {
	width: 10rem;
	height: 11rem;
}

.motivationCard {
	min-width: 45%;
}

.ffi {
	vertical-align: middle;
	font-size: 1.4rem;
}

.ffi-d6 {
	letter-spacing: 0;
}

.ffi-border {
	-webkit-text-stroke-color: black;
	-webkit-text-stroke-width: 0.1rem;
}

.bg {
	position: fixed;
	background-size: contain;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
}

.imageBox {
	position: relative;
	text-align: center;
	color: black;
	padding: 0;
	margin: 0.15rem;
	width: 6rem;
}

.attribute {
	width: 10rem;
	height: 5.5rem;
}

.xpBox {
	width: 8rem;
}

.totalXP {
	position: fixed;
	bottom: 10px;
	left: 10px;
	z-index: 1;
}

.availableXP {
	position: fixed;
	bottom: 10px;
	right: 10px;
	z-index: 1;
}

.attributeValue {
	position: absolute;
	display: inline-flex;
	align-items: center;
	font-size: 1.5rem;
	top: 55%;
	left: 57%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
	flex: unset;
	padding: 0 0;
	font-weight: bold;
}

.attributeInput {
	font-size: 1.2rem;
	width: 2rem;
	height: 2rem;
	padding: 0;
	text-align: center;
}

.characteristicValue {
	position: absolute;
	font-size: 2.5rem;
	top: 39%;
	left: 62.5%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
	flex: unset;
	font-weight: bold;
}

.xpValue {
	position: absolute;
	font-size: 2rem;
	font-weight: bold;
	top: 45%;
	left: 60%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
	flex: unset;
}

/*CRB Theme*/
.body-CRB {
	font-family: 'Avenir Next LT Pro', sans-serif !important;
}

.header-CRB {
	font-family: 'Bebas Neue', sans-serif !important;
	font-size: 1.75rem;
}

.bg-CRB {
	background-image: url(/static/media/background.fd330822.png);
}

.attributeValue-CRB-Wounds,
.attributeValue-CRB-Defense,
.attributeValue-CRB-Soak,
.attributeValue-CRB-Strain {
	left: 59%
}

.characteristicValue-CRB {
	top: 37%;
	left: 65%;
}

/*ROT Theme*/
.bg-ROT {
	background-image: url(/static/media/background.f747855a.png);
}

.body-ROT {
	font-family: 'Adobe Garamond Pro', sans-serif !important;
}

.header-ROT {
	font-family: 'Bolton', sans-serif !important;
	font-size: 1rem;
}

.xpValue-ROT {
	top: 36%
}

.attributeValue-ROT-Wounds,
.attributeValue-ROT-Soak,
.attributeValue-ROT-Defense,
.attributeValue-ROT-Strain {
	top: 58%;
	left: 58%;
}

.characteristicValue-ROT {
	left: 65.5%;
	top: 41%;
}

/*SOTB Theme*/
.bg-SOTB {
	background: url(/static/media/background.b0c62bb9.png) center;
}

.body-SOTB {
	font-family: 'Avenir Next LT Pro', sans-serif !important;
}

.header-SOTB {
	font-family: 'Bebas Neue', sans-serif !important;
	font-size: 1.75rem;
}

.characteristicValue-SOTB {
	top: 36%;
	left: 65.5%;
}

.xpValue-SOTB {
	top: 70%;
}

.attributeValue-SOTB-Wounds,
.attributeValue-SOTB-Soak,
.attributeValue-SOTB-Defense,
.attributeValue-SOTB-Strain,
.attributeValue-SOTB-StrainThreshold,
.attributeValue-SOTB-WoundsThreshold {
	top: 61%;
	left: 58%;
}

/*CCC Theme
.bg-CCC {
	background: url(../images/CCC/background.png) center;
}

.body-CCC {
	font-family: 'Charis SIL', sans-serif !important;
}

.header-CCC {
	font-family: 'American Captian', sans-serif !important;
}

.characteristicValue-CCC {
	top: 36%;
}

.xpValue-CCC {
	top: 65%;
}*/

@media only screen
and (max-width: 480px) {

	.table {
		font-size: 0.7rem;
	}

	.fontSizeSmall {
		font-size: 0.5rem;
	}

	.talentCard {
		width: 16vw;
		height: 12vh;
		font-size: 0.5rem;
	}

	.motivationCard {
		width: 100%;
	}

	.ffi {
		font-size: 1rem;
	}

	.overflowX {
		overflow-x: scroll;
	}
}

@media print {
	* {
		-webkit-print-color-adjust: exact;
	}

	body {
		--orangeFade: var(--orange);
		--lightblueFade: var(--lightblue);
	}

	.no-break {
		page-break-inside: avoid;
		-webkit-column-break-inside: avoid;
		        break-inside: avoid;
	}

	.break-after {
		page-break-after: always;
	}

	.break-before {
		page-break-before: always;
	}

	.table th {
		padding: 0.1rem;
	}

	.table td {
		padding: 0 0.2rem;
	}

	.talentCard {
		width: 8rem;
		height: 1rem;
	}

	.ffi {
		font-size: 12pt;
	}

	.ffi-border {
		-webkit-text-stroke-width: 0.05rem;
	}

	.table-dice {
		width: 200px;
	}

	.table-career {
		width: 70px;
	}

	.table-rank {
		width: 70px;
	}

	.table-name {
		width: 250px;
	}

	.table-skills {
		font-size: 14pt;
	}

	.totalXP {
		display: inline-block;
		position: relative;
	}

	.availableXP {
		display: inline-block;
		position: relative;
	}

	.header-CRB {
		font-size: 1.2rem;
	}

	.attributeValue-CRB-Wounds,
	.attributeValue-CRB-Strain,
	.attributeValue-CRB-Soak,
	.attributeValue-CRB-Defense {
		left: 55%;
	}

	.xpValue-CCC {
		top: 54%;
	}

	.header-SOTB {
		font-size: 1.2rem;
	}

	.xpValue-SOTB {
		font-size: 1.2rem;
	}

	.attributeValue-SOTB-Wounds,
	.attributeValue-SOTB-Strain,
	.attributeValue-SOTB-Soak,
	.attributeValue-SOTB-Defense {
		font-size: 1.2rem;
	}
}
