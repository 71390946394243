.react-tabs__tab-list {
	width: 100%;
	text-align: left;
	border-bottom: 1px solid #aaa;
	margin: 0 0 10px;
	padding: 0;
}

.react-tabs__tab {
	display: inline-block;
	border: 1px solid transparent;
	border-bottom: none;
	bottom: -1px;
	position: relative;
	list-style: none;
	padding: 6px 12px;
	cursor: pointer;
	font-size: 0.8rem;
}

.react-tabs__tab--selected {
	background: var(--gray);
	border-color: #aaa;
	color: black;
	border-radius: 5px 5px 0 0;
}

.react-tabs__tab--disabled {
	color: GrayText;
	cursor: default;
}

.react-tabs__tab:focus {
	box-shadow: 0 0 5px hsl(208, 99%, 50%);
	border-color: hsl(208, 99%, 50%);
	outline: none;
}

.react-tabs__tab:focus:after {
	content: "";
	position: absolute;
	height: 5px;
	left: -4px;
	right: -4px;
	bottom: -5px;
	background: #fff;
}

.react-tabs__tab-panel {
	display: none;
}

.react-tabs__tab-panel--selected {
	display: block;
}
